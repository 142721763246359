import React from 'react';
// import '../../styles/Text_c.css'; // Make sure to import your CSS if needed
const config = require('../../config/development.js');

function Text_c({ Text }) {
  const rocketship = `${config.baseUrl}/images/spaceship.png`; 

  return (
    <div>
      {Text.map((text) => (
        <div className='single_container' key={text.title}>
          <img src={rocketship} alt="Rocketship Image" style={{ width: ' 50px', height: 'auto' }} />
          <h1>{text.title}</h1>
          <p className="Text">{text.text}</p>
        </div>
      ))}
      <img src={rocketship} alt="Rocketship Image" style={{ width: '50px', height: 'auto', transform: 'rotate(180deg)' }} />   
    </div>
  );
}

export default Text_c;