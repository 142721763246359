import React, { useEffect, useState } from 'react';
import '../styles/style.css'; // Import your CSS file
import PersonList from './components/Personen';
import ContactForm from './components/ContactForm';
import ButtonNavigation from './components/Button';
import Text_c from './components/Text_c';
import '../fonts/01_PressStart2P-Regular.ttf';
const config = require('../config/development.js');


function Home() {
  const [Themen, setThemen] = useState([]);
  const [Personen, setPerson] = useState([]);
  const [Text, setText] = useState([]);
  const backgroundImageUrl = `${config.baseUrl}/images/body.png`; 

  const fetchData = async () => {
    try {
      const response1 = await fetch(`${config.baseUrl}/news`);
      const ThemenData = await response1.json();
      setThemen(ThemenData);

      const response2 = await fetch(`${config.baseUrl}/personen`);
      const PersonData = await response2.json();
      setPerson(PersonData);

      const response3 = await fetch(`${config.baseUrl}/text`);
      const TextData = await response3.json();
      setText(TextData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
   
 
  return (
    <div> 
      <div className="container" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <Text_c Text={Text}/>
        <ButtonNavigation Themen={Themen}/>
        <div className='single_container'>
          <PersonList Personen={Personen} />
        </div>
        <div className='single_container'>
          <ContactForm />
        </div>

      </div>
    </div>
  );
}
export default Home;
