  import React from 'react';
  import '../../styles/Footer.css';
  import { NavLink } from 'react-router-dom';
  const config = require('../../config/development.js');

  function Footer() {
    const backgroundImageUrl = `${config.baseUrl}/images/foot.png`; 

    return (
      <footer style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <NavLink to="/Impressum" className='Leftcorner'>Impressum</NavLink>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/Datenschutz" className='Rightcorner'>Datenschutz</NavLink>
      </footer>
    );
  }

  export default Footer;
