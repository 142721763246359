/*module.exports = {
  corsOrigins: ['http://localhost', ],
  staticImageDirectory: 'images',
  staticFontDirectory: 'fonts',
  port: 4000,
  baseUrl: 'http://localhost:4000',
};*/
module.exports = {
  corsOrigins: ['http://www.phase-games.de', 'http://phase-games.de'], // Define your allowed origins here
  staticImageDirectory: 'images',
  staticFontDirectory: 'fonts',
  port: 4000,
  baseUrl: 'http://185.232.68.90:4000',
};
