import { NavLink } from "react-router-dom"
import '../fonts/01_PressStart2P-Regular.ttf';
const config = require('../config/development.js');

export default function Notfound() {
 const backgroundImageUrl = `${config.baseUrl}/images/body.png`; 
    
  return (
    <div> 
    
      <div className="container_info" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <div className='single_container'>
        <h1>Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
}