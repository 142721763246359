import { Outlet, NavLink } from "react-router-dom"
import Header from '../Pages/components/Header';
import Footer from "../Pages/components/Footer";

export default function RootLayout() {
  return (
    <div className="root-layout">
      <Header>

      </Header>
      <main>
        <Outlet />
      </main>
      <Footer>

      </Footer>
    </div>
  )
}