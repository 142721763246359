import React, { useState, useEffect } from 'react';
import '../../styles/Header.css';
const config = require('../../config/development.js');

function Header() {
  const [isHeaderShrunk, setHeaderShrunk] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHeaderShrunk(true);
      } else {
        setHeaderShrunk(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundImageUrl = `${config.baseUrl}/images/header.png`; // Set the background image URL here

  return (
    <header className={isHeaderShrunk ? 'shrink' : ''} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <img src={`${config.baseUrl}/images/Logo.png`} alt="Phase Games Logo" className="logo" />
    </header>
  );
}

export default Header;
