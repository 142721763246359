import React from 'react';
import { createBrowserRouter, createRoutesFromElements,Route, RouterProvider} from 'react-router-dom'

import Home from './Pages/Home'
import Impresum from './Pages/Impresum'
import Datenschutz from './Pages/Datenschutz'
import RootLayout from './layouts/Rootlayout'
import NotFound from './Pages/Notfound';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="Impressum" element={<Impresum />} />
      <Route path="Datenschutz" element={<Datenschutz />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App