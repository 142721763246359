import '../fonts/01_PressStart2P-Regular.ttf';
import '../styles/Datenschutz.css'; 
const config = require('../config/development.js');

export default function Impresum() {
 const backgroundImageUrl = `${config.baseUrl}/images/body.png`; 
    
  return (
    <div> 
    
      <div className="container_info" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <div className='single_container'>
        <div className='Textbox'>
        <h1>Impressum</h1>
        <h2>Impressum für die Webseite “phase-games.de”</h2>
        <h3>Betreiber dieser Webseite:</h3>
        <p>Niki Florian Hendel<br/>
        Beethovenstraße 7<br/>
        09130 Chemnitz<br/>
        E-Mail: Hendel33333@gmail.com<br/>
        Tel: +49 172 4567 402</p>
        <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
        <p>Niki Florian Hendel<br/>
        Beethovenstraße 7<br/>
        09130 Chemnitz</p>
        <h2>Haftungsausschluss:</h2>
        <p>Die Informationen auf dieser Webseite dienen ausschließlich zu Informationszwecken. Wir übernehmen keine Gewähr für die Aktualität, Richtigkeit oder Vollständigkeit der bereitgestellten Informationen. Die Nutzung der Inhalte erfolgt auf eigene Gefahr.</p>
        <h2>Urheberrecht und Markenschutz:</h2>
        <p>Die Inhalte dieser Webseite unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des Autors bzw. Erstellers. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.</p>
        <h2>Hinweis zu externen Links:</h2>
        <p>Diese Webseite enthält Verknüpfungen zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
        </div>
        </div>
      </div>
    </div>
  );
}